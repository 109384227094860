<template>
  <div class="dj-login">
    <div class="title fw-bold text-center">{{ title }}</div>
    <br />
    <br />
    <form ref="loginForm" :action="action" method="POST" autocomplete="off">
      <el-form ref="ruleForm" :model="form" :rules="rules">
        <el-form-item v-show="false" proo="schoolName">
          <el-input placeholder="学校名称" v-model="form.schoolName"></el-input>
          <input type="hidden" name="schoolName" value="dejia" />
        </el-form-item>
        <el-form-item prop="username">
          <el-input placeholder="用户名" v-model="form.username"></el-input>
          <input type="hidden" name="username" :value="form.username" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input placeholder="密码" type="password" v-model="form.password"></el-input>
          <input type="hidden" name="password" :value="form.password" />
        </el-form-item>
        <el-form-item>
          <br />
          <el-button type="primary" style="width: 100%" @click="onSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </form>
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue'
// import axios from 'axios'
// import { useRouter, useRoute } from 'vue-router'

defineProps({
  title: { type: String, default: '' },
  action: { string: String, default: '' }
})

// const route = useRoute()
// const router = useRouter()

const form = ref({ schoolName: 'dejia', username: '', password: '' })
const ruleForm = ref()
const loginForm = ref()

const rules = {
  username: { required: true, type: 'string', message: '请输入用户名', trigger: ['blur'] },
  password: { required: true, type: 'string', message: '请输入密码', trigger: ['blur'] }
}

// const isTeacher = computed(() => route.name === 'dejia.teacher')
// const switchUserType = computed(() => (isTeacher.value ? '学生' : '教师'))

const onSubmit = () => {
  ruleForm.value.validate(v => {
    if (!v) return
    loginForm.value.submit()
    // axios.post(this.action, {
    //   schoolName: form.value.schoolName,
    //   username: form.value.username,
    //   password: form.value.password
    // })
  })
}

// const onSwitchUserType = () => {
//   if (isTeacher.value) {
//     router.push({ name: 'dejia.student' })
//   } else {
//     router.push({ name: 'dejia.teacher' })
//   }
// }
</script>

<style scoped>
.dj-login {
  position: fixed;
  right: 12%;
  top: 50%;
  width: 420px;
  padding: 60px 30px;
  background-color: #ffffff80;
  border-radius: 20px;
  height: 436px;
  transform: translateY(-50%);
}
.title {
  font-size: 28px;
  color: #212529;
}
</style>
