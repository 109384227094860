<template>
  <div class="dejia-teacher">
    <login-container :title="title" :action="action"></login-container>
  </div>
</template>

<script setup>
import LoginContainer from './login-container.vue'

const title = '德佳教育 - 教师端'
const action = 'https://teacher.tzspace.cn/login/authenticate'
</script>

<style>
.dejia-teacher {
  height: 100%;
  background-image: url('~@/assets/images/schools/dejia/bg-colorful.jpg');
  background-size: 100% 100%;
}
</style>
